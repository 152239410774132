<template>
    <div>
      <Error
        :icon="NotFoundIcon"
        message="There is an error on our side"
        subtext="Please reload this page or try again later."
        link="/dashboard"
        linkText="Go To Mekari Developer Center"
      />
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from "vue";
  import Error from "@/views/errors/Error.vue";
  import NotFoundIcon from "@/assets/images/error/5xx.svg";
  
  export default defineComponent({
    components: {
      Error,
    },
    data() {
      return {
        NotFoundIcon,
      };
    },
  });
  </script>