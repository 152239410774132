
  import { defineComponent } from "vue";
  import Error from "@/views/errors/Error.vue";
  import NotFoundIcon from "@/assets/images/error/5xx.svg";
  
  export default defineComponent({
    components: {
      Error,
    },
    data() {
      return {
        NotFoundIcon,
      };
    },
  });
  